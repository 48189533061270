@import '../../../../scss/theme-bootstrap';

.product-add-favorites {
  &:active,
  &:link,
  &:hover,
  &:visited {
    outline: none;
    text-decoration: none;
  }
}

.tooltipster-darphin {
  background: $color-gray;
  color: $color-white;
  max-width: 300px;
  a {
    color: $color-white;
    text-decoration: underline;
    &:hover {
      color: $color-gray;
    }
  }
  .tooltipster-content {
    overflow: hidden;
    text-transform: uppercase;
    padding: 15px;
    font-size: 15px;
    color: $color-dark-gray;
    line-height: 20px;
    background-color: $color-cyan-green;
    a,
    p {
      color: $color-dark-gray;
    }
  }
  .anon-message {
    display: block;
    text-transform: none;
    body.signed-in & {
      display: none;
    }
    a {
      color: $color-dark-gray;
    }
  }
}
